import classNames from 'classnames';
import CtaLink from 'components/CtaLink';
import { useTranslation } from 'next-i18next';
import React from 'react';

import styles from './style.module.scss';

export type CtaBackgroundPattern =
  | 'green-centered'
  | 'green-side'
  | 'green-small'
  | 'red-centered'
  | 'red-side'
  | 'red-small';

interface BottomCTAProps {
  title: string;
  handleClickOrRedirectUrl: string | ((e: React.MouseEvent) => void);
  linkTarget?: string;
  handleSecondaryClickOrRedirectUrl?: string | ((e: React.MouseEvent) => void);
  secondaryLinkTarget?: string;
  action?: string;
  secondaryAction?: string;
  description?: React.ReactNode | string;
  className?: string;
  backgroundColor?: 'light' | 'dark';
  layout?: 'full' | 'center';
  textPosition?: 'left' | 'center';
  backgroundPattern?: CtaBackgroundPattern;
  smallHeading?: boolean;
  ctaLinkId?: string;
}

const BottomCTA = ({
  layout = 'full',
  textPosition = 'left',
  backgroundPattern = 'green-centered',
  smallHeading = false,
  linkTarget = '_self',
  secondaryLinkTarget = '_self',
  ctaLinkId,
  ...props
}: BottomCTAProps) => {
  const { t } = useTranslation();
  const {
    title,
    handleClickOrRedirectUrl,
    handleSecondaryClickOrRedirectUrl,
    description,
    className,
    backgroundColor,
    action = t('component.bottomcta.action.default', 'Get covered'),
    secondaryAction = t(
      'component.bottomcta.secondaryaction.default',
      'Talk to us'
    ),
  } = props;

  const primaryCTA =
    typeof handleClickOrRedirectUrl === 'string' ? 'a' : 'button';

  const secondaryCTA = handleSecondaryClickOrRedirectUrl
    ? typeof handleSecondaryClickOrRedirectUrl === 'string'
      ? 'a'
      : 'button'
    : null;

  return (
    <div
      className={classNames(styles['full-width-wrapper'], {
        [styles['full-width-wrapper--light']]: backgroundColor === 'light',
      })}
    >
      <div
        className={classNames(className, styles.container, {
          'p-body': layout === 'center',
          [styles[`container--bg-${backgroundPattern}-full`]]:
            layout === 'full',
          [styles['container--bg-green-centered']]:
            backgroundPattern === 'green-centered',
          [styles['container--bg-green-side']]:
            backgroundPattern === 'green-side',
          [styles['container--bg-green-small']]:
            backgroundPattern === 'green-small',
          [styles['container--bg-red-centered']]:
            backgroundPattern === 'red-centered',
          [styles['container--bg-red-side']]: backgroundPattern === 'red-side',
          [styles['container--bg-red-small']]:
            backgroundPattern === 'red-small',
        })}
      >
        <div
          className={classNames([styles.wrapper], {
            [styles['wrapper--center']]: textPosition === 'center',
            'p-body': layout === 'full',
            [styles['wrapper--padding']]:
              textPosition === 'left' && layout === 'center',
          })}
        >
          {!smallHeading ? (
            <h2
              className={classNames('p-h2 p--serif w100', {
                'ta-center': textPosition === 'center',
              })}
            >
              {title}
            </h2>
          ) : (
            <h3
              className={classNames('p-h3 p--serif w100', {
                'ta-center': textPosition === 'center',
              })}
            >
              {title}
            </h3>
          )}
          {description &&
            (typeof description === 'string' ? (
              <p className="p-p mt8 tc-grey-700">{description}</p>
            ) : (
              description
            ))}
          {primaryCTA === 'a' && (
            <CtaLink
              className="p-btn--primary mt16"
              href={handleClickOrRedirectUrl as string}
              target={linkTarget}
              ctaLinkId={ctaLinkId}
            >
              {action as string}
            </CtaLink>
          )}
          {primaryCTA === 'button' && (
            <button
              className="p-btn--primary mt16"
              onClick={handleClickOrRedirectUrl as () => void}
              type="button"
            >
              {action}
            </button>
          )}
          {!!secondaryCTA && secondaryCTA === 'a' && (
            <CtaLink
              className="p-btn--secondary ml8 mt16"
              href={handleSecondaryClickOrRedirectUrl as string}
              target={secondaryLinkTarget}
            >
              {secondaryAction as string}
            </CtaLink>
          )}
          {!!secondaryCTA && secondaryCTA === 'button' && (
            <button
              className="p-btn--secondary ml8 mt16"
              onClick={handleSecondaryClickOrRedirectUrl as () => void}
              type="button"
            >
              {secondaryAction}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default BottomCTA;
