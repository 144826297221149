import { FilledLinkToWebField } from '@prismicio/types';
import classNames from 'classnames';
import BottomCTA, { CtaBackgroundPattern } from 'components/bottomCTA';
import MissingRequiredFields from 'components/MissingRequiredFields';
import React from 'react';
import { CtaBannerSlice } from 'types.generated';

import styles from './CtaBanner.module.scss';

export const CtaBanner = ({ slice }: { slice: CtaBannerSlice }) => {
  const {
    title,
    description,
    primary_cta_text: primaryCtaText,
    primary_cta_link: primaryCtaLink,
    secondary_cta_text: secondaryCtaText,
    secondary_cta_link: secondaryCtaLink,
    background_pattern: backgroundPatternOption,
    is_full_width: isFullWidth,
    cta_link_id: ctaLinkId,
  } = slice.primary;
  if (!title) return <MissingRequiredFields />;

  let backgroundPattern: CtaBackgroundPattern;
  switch (backgroundPatternOption) {
    case 'GREEN_CENTERED':
      backgroundPattern = 'green-centered';
      break;
    case 'GREEN_SIDE':
      backgroundPattern = 'green-side';
      break;
    case 'GREEN_SMALL':
      backgroundPattern = 'green-small';
      break;
    case 'RED_CENTERED':
      backgroundPattern = 'red-centered';
      break;
    case 'RED_SIDE':
      backgroundPattern = 'red-side';
      break;
    case 'RED_SMALL':
      backgroundPattern = 'red-small';
      break;
    default:
      backgroundPattern = 'green-centered';
  }

  return (
    <section
      className={classNames('mt56', styles.container, {
        'p-container': !isFullWidth,
        [styles.smallCTAContainer]: !isFullWidth,
      })}
    >
      <BottomCTA
        title={title}
        description={description}
        action={primaryCtaText || undefined}
        ctaLinkId={ctaLinkId as string}
        handleClickOrRedirectUrl={
          (primaryCtaLink.link_type === 'Web' &&
            (primaryCtaLink as FilledLinkToWebField).url) ||
          (() => {})
        }
        secondaryAction={secondaryCtaText || undefined}
        handleSecondaryClickOrRedirectUrl={
          (secondaryCtaLink.link_type === 'Web' &&
            (secondaryCtaLink as FilledLinkToWebField).url) ||
          undefined
        }
        backgroundPattern={backgroundPattern}
        linkTarget={
          primaryCtaLink.link_type === 'Web'
            ? (primaryCtaLink as FilledLinkToWebField).target
            : undefined
        }
        secondaryLinkTarget={
          secondaryCtaLink.link_type === 'Web'
            ? (secondaryCtaLink as FilledLinkToWebField).target
            : undefined
        }
      />
    </section>
  );
};
